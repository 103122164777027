import {useQuery} from "react-query";
import useApi from "../axios";

export const useContents = () => {
    const { get } = useApi();

    return useQuery({
        queryFn: async () => {
            return await get("/contents/");
        },
        queryKey: ["contents"],
    });
};
export const useContentsId = (id) => {
    const { get } = useApi();

    return useQuery({
        queryFn: async () => {
            return await get("/contents/"+id);
        },
        queryKey: ["contents_id"],
    });
};
export const useContentId = (id) => {
    const { get } = useApi();

    return useQuery({
        queryFn: async () => {
            return await get("/contents/"+id+"/show");
        },
        queryKey: ["content_id"],
        enabled: !!id,
    });
};
export const useSearch = (search) => {
    const { get } = useApi();

    return useQuery({
        queryFn: async () => {
            return await get("/contents/search/"+search);
        },
        queryKey: ["content_search"],
    });
};
