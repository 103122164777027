import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {Search} from "./Search";
import {useLocation} from "react-router-dom";
import Bandeau from "./Bandeau";

function Nav ({searchVisible, setSearchVisible, onClose}) {
    const location = useLocation();
    //alert(location.pathname);
    const [search, setSearch] = useState('');

    // Gestion du clic sur la loupe
    const toggleSearch = () => {
        setSearchVisible(!searchVisible);
    };

    //if(location.pathname === "/")
    //    return null ;

    const isHome = () => {
        return location.pathname === "/";
    }

    const link = (id, ancre, name, style) => {
        if(isHome())
            return (<li className="nav-item" style={style}><a className="nav-link" href="/" data-scroll-nav={id}>{name}</a></li>);
        else
            return (<li className="nav-item" style={style}><a className="nav-link" href={'/#' + ancre}>{name}</a></li>);
    }

    return (
        <>

            <nav className="navbar navbar-expand-lg nav-scroll">
                <div className="container" style={{marginLeft: '140px'}}>
                    <div className="logo-wrapper">
                        <a className="logo" href="/"> <img src="/img/logo-light.png" alt=""/> </a>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation"><span className="icon-bar"><i className="ti-menu"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {link(0, "", "Accueil", {})}
                            {link(1, "about", "Qui sommes-nous", {width: '146px'})}
                            {link(2, "services", "Notre palette d'outils", {width: '185px'})}
                            {link(3, "industriel", "L'Industriel", {})}
                            {link(4, "architecte", "L'Architecte", {})}
                            {link(4, "architecte", "L'Installateur", {})}
                            {link(5, "products", "La cour des matériaux", {width: '183px'})}
                            {link(6, "blog", "Actualités", {})}
                            {link(7, "contact", "Contact", {})}
                            <li className="nav-item"><a className="nav-link" href="#" onClick={toggleSearch}><FontAwesomeIcon icon={faSearch} /></a></li>
                        </ul>
                    </div>
                </div>

                <Bandeau />
            </nav>

            {/* Bloc de recherche conditionnel */}
            {searchVisible && (
                <SearchWrapper onMouseLeave={onClose}>
                    <SearchBlock>
                        <Search s={search} setS={setSearch} setSearchVisible={setSearchVisible} />
                    </SearchBlock>
                </SearchWrapper>
            )}
        </>
    )
}

export default Nav;

const SearchWrapper = styled.div`
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
`

const SearchBlock = styled.div`
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`
