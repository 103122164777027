import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import {useLead} from "../services/api/leads/use-lead";

function Contact() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [secteur, setSecteur] = useState('');
  const { mutateAsync: createLead } = useLead();


    const handleSubmit = async () => {
        try {
            const response = await createLead({ name, company, phone, email, message, secteur });
            if (response) {
                navigate("/");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onsubmit = (e) => {
        e.preventDefault();
    }

  return (
      <div className="App">
          <div id="products" className="savoye-project section-padding" data-scroll-index="3">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 text-center">
                          <div className="section-title">Contactez-nous</div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="section-padding">
              <div className="container">
                  <div className="savoye-comment-section">
                      <div className="row">
                          <div className="col-md-12">
                              <h6>Faites une demande</h6>
                              <form onSubmit={onsubmit} className="row">
                                  <div className="col-md-12">
                                      <input
                                          placeholder="Nom *"
                                          type="text"
                                          id="name"
                                          name="name"
                                          value={name}
                                          onChange={(e) => setName(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <input
                                          placeholder="Entreprise *"
                                          type="text"
                                          id="company"
                                          name="company"
                                          value={company}
                                          onChange={(e) => setCompany(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <input
                                          placeholder="Téléphone *"
                                          type="text"
                                          id="phone"
                                          name="phone"
                                          value={phone}
                                          onChange={(e) => setPhone(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <input
                                          placeholder="Email *"
                                          type="text"
                                          id="email"
                                          name="email"
                                          value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <textarea
                                          placeholder="Message *"
                                          id="message"
                                          name="message"
                                          value={message}
                                          onChange={(e) => setMessage(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <input
                                          placeholder="Secteur : MOE / MOA / Installateur / ... *"
                                          type="text"
                                          id="secteur"
                                          name="secteur"
                                          value={secteur}
                                          onChange={(e) => setSecteur(e.target.value)}
                                          required
                                      />
                                  </div>
                                  <div className="col-md-12">
                                      <button type="submit" onClick={() => handleSubmit()}>Envoyer</button>
                                  </div>

                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className=" section-padding"/>
          <div className=" section-padding"/>
          <Footer/>
      </div>
  );
}

export default Contact;
