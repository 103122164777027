import React from "react";

function Bandeau() {
    return (
        <div className="container-fluid "
             style={{
                 marginTop: '150px',
                 position: 'absolute',
                 zIndex: 999999,
                 width: '100%',
                 background: '#166082'
            }}>
            <div className="row" style={{
                 textAlign: 'center',
                right: 0,
                left: 0,
                margin: 'auto'
            }}>
                <div className="col-md-12 text-center">
                    <a href={'/industriel'}>
                        <h5 style={{margin: '15px 0px 15px 0px', color: 'white'}} className="text-center">
                            <b
                                style={{fontWeight: 600, color: 'white'}}>Boostez votre compétitivité : inscrivez-vous
                            maintenant pour profiter de notre offre de lancement exclusive et innovante !</b>
                        </h5>
                    </a>
            </div>
        </div>
        </div>
    )
}

export default Bandeau;
