import React from "react";
import {useNavigate} from "react-router-dom";

function QuickActions() {
  const navigate = useNavigate();
  return (
    <div className="MenuButton">
      <div className="MenuButton__Button MenuButton__Base">
        <i className="ti-plus MenuButton__Base__icon"></i>
      </div>
      <div className="MenuButton__dropdown MenuButton__dropdownDiv">
        {/* eslint-disable-next-line */}
        <div
          className="MenuButton_buttonDiv MenuButton_button-note"
          onClick={() => navigate('/industriel')}
        >
          <i className="fas fa-edit" />
          <p>Industriel</p>
        </div>
        {/* eslint-disable-next-line */}
        <div
          className="MenuButton_buttonDiv MenuButton_button-note"
          onClick={() => navigate('/architecte')}
        >
          <i className="fa-solid fa-list-check" />
          <p>Architecte</p>
        </div>
        {/* eslint-disable-next-line */}
        <div
          className="MenuButton_buttonDiv MenuButton_button-note"
          onClick={() => navigate('/installateur')}
        >
          <i className="fas fa-upload" />
          <p>Installateur</p>
        </div>
      </div>
    </div>
  );
}

export default QuickActions;
