import React, {useState} from "react";
import styled from "styled-components";
import {Search} from "../components/Search";
import {useSearch} from "../services/api/contents/use-contents";
import {useParams} from "react-router-dom";

export const ResultsSearch = () => {
    const { search: s } = useParams();
    const {data: searchs} = useSearch(s);
    console.log(JSON.stringify(searchs));
    const [search, setSearch] = useState(s);

    const show = element => (
        <Card key={element.id}>
            {element.image && (
                <ProductImage src={element.image.url} alt={element.title} />
            )}
            <ContentProduct>
                <Title>{element.title}</Title>
                <Description dangerouslySetInnerHTML={{__html: element?.description}} />
            <ProductLink href={"https://www.prescriptionmateriaux.com"+element.url}>https://www.prescriptionmateriaux.com{element.url}</ProductLink>
            </ContentProduct>
        </Card>
    )

    return (
        <div className="section-padding">
            <div className="section-mi-padding" />
            <div className="container">
                <SearchBarResult>
                    <Search s={search} setS={setSearch} />
                </SearchBarResult>
                <div className="row">
                    <div className="col-md-12">
                        {searchs?.filter(element => element?.image?.url !== null).sort((a, b) => a.order - b.order).map((element) => (
                            show(element)
                        ))}
                        {searchs?.filter(element => element?.image?.url === null).sort((a, b) => a.order - b.order).map((element) => (
                            show(element)
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


const Card = styled.div`
    background: white;
    border-radius: 8px;
    padding: 15px;
    transition: transform 0.2s;
    margin-bottom: 30px;
    display: flex;
`

const ProductImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
`
const ContentProduct = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Title = styled.h2`
    font-size: 20px;
    margin: 10px 0;
`

const Description = styled.div`
    font-size: 16px;
    color: #555;
`

const ProductLink = styled.a`
    display: inline-block;
    margin-top: 10px;
    color: #3498db;
    font-family: 'Khand', sans-serif;
`

const SearchBarResult = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
 `
