import React from 'react';
import Footer from "../components/Footer";

function Video() {

  return (
      <div className="App">
          <div style={{position: 'relative', paddingTop: '10%', paddingBottom: '46.25%', height: 0, overflow: 'hidden'}}>
              <iframe width="1120" height="630" src="https://www.youtube.com/embed/_POH-QU5E4s"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>
          <Footer/>
      </div>
  );
}

export default Video;
