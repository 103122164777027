import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useContents} from "../services/api/contents/use-contents";
import Footer from "../components/Footer";
import {useIndustriel} from "../services/api/industriels/use-industriel";

function Industriel() {
    const navigate = useNavigate();
    const {data: contents} = useContents();
    const products = contents?.filter(content => content.content_type === "products" && content.content_id === null);
    const [name, setName] = useState('');
    const [firstname, setFirstname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codepostal, setCodepostal] = useState('');
    const [ville, setVille] = useState('');
    const [secteur, setSecteur] = useState('');
    const [region, setRegion] = useState('');
    const [projet, setProjet] = useState('');
    const [comment, setComment] = useState('');
    const [siret, setSiret] = useState('');
    const [naf, setNaf] = useState('');
    const { mutateAsync: createIndustriel } = useIndustriel();

    const handleSubmitIndustriel = async () => {
        try {
            if (!!name && !!firstname && !!email && !!company && !!adresse && !!codepostal && !!ville && !!secteur) {
                const response = await createIndustriel({
                    name,
                    firstname,
                    phone,
                    email,
                    company,
                    secteur,
                    adresse,
                    codepostal,
                    ville,
                    region,
                    siret,
                    naf,
                    projet,
                    comment
                });
                if (response) {
                    setName('');
                    setFirstname('');
                    setPhone('');
                    setEmail('');
                    setCompany('');
                    setAdresse('');
                    setCodepostal('');
                    setVille('');
                    setSecteur('');
                    setRegion('');
                    setProjet('');
                    setComment('');
                    setSiret('');
                    setNaf('');
                    navigate('/');
                    navigate(0);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onsubmit = (e) => {
        e.preventDefault();
    }

  return (
      <div className="App">
        <div className="row">
            <div className="offset-1 col-md-10">
                <div className="section-padding"/>
                <h6>Devenez membre du réseau P&M</h6>
                <form onSubmit={onsubmit} className="row">
                    <div className="col-md-12">
                        <input
                            placeholder="Nom *"
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Prénom *"
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Email *"
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Téléphone"
                            type="text"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Entreprise *"
                            type="text"
                            id="company"
                            name="company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Adresse *"
                            type="text"
                            id="adresse"
                            name="adresse"
                            value={adresse}
                            onChange={(e) => setAdresse(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Code postal *"
                            type="text"
                            id="codepostal"
                            name="codepostal"
                            value={codepostal}
                            onChange={(e) => setCodepostal(e.target.value)}
                            required
                        />
                    </div>

                    <div className="col-md-12">
                        <input
                            placeholder="Ville *"
                            type="text"
                            id="ville"
                            name="ville"
                            value={ville}
                            onChange={(e) => setVille(e.target.value)}
                            required
                        />
                    </div>

                    <div className="col-md-12">
                        <input
                            placeholder="Région"
                            type="text"
                            id="region"
                            name="region"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                        />
                    </div>

                    <div className="col-md-12">
                        <input
                            placeholder="SIRET"
                            type="text"
                            id="siret"
                            name="siret"
                            value={siret}
                            onChange={(e) => setSiret(e.target.value)}
                        />
                    </div>

                    <div className="col-md-12">
                        <input
                            placeholder="NAF"
                            type="text"
                            id="naf"
                            name="naf"
                            value={naf}
                            onChange={(e) => setNaf(e.target.value)}
                        />
                    </div>

                    <div className="col-md-12 secteur-form">
                        <select id="secteur" className={"col-md-12 select-form"} value={secteur} onChange={(e) => setSecteur(e.target.value)}>
                            <option value="">Secteur *</option>
                            {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                                <option value={item.id}>{item.title}</option>)))}
                        </select>
                    </div>

                    <div className="col-md-12 div-check-form">
                        <div className={"check-form"}>
                            <div className={"check-check-form"}>
                                <input
                                    type="checkbox"
                                    name="projet"
                                    value="Oui"
                                    checked={projet === "Oui"}
                                    onChange={(e) => setProjet(e.target.value)}
                                />
                                <div className={"check-check-label-form"}>Oui, j'ai un projet</div>
                            </div>

                            <div className={"check-check-form"}>
                                <input
                                    type="checkbox"
                                    name="projet"
                                    value="Non"
                                    checked={projet === "Non"}
                                    onChange={(e) => setProjet(e.target.value)}
                                />
                                <div className={"check-check-label-form"}>Non, je n'ai pas de projet</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12  div-check-form">Votre projet</div>

                    <div className="col-md-12">
                        <textarea
                            placeholder={"Décrivez votre besoin en détail pour obtenir une meilleure réponse : \n " +
                                "- Caractéristiques / options ; \n" +
                                "- Taille / Volume ; \n" +
                                "- Usage / Contexte ; \n" +
                                "- Matériaux / Finitions ; \n" +
                                "- Délais / Lieux de livraison ; \n" +
                                "- Autre. "}
                            id="comment"
                            name="comment"
                            className={"textarea-form"}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>

                    <div className="col-md-12">
                        <button type="submit" onClick={() => handleSubmitIndustriel()}>Envoyer</button>
                    </div>

                </form>
            </div>
        </div>
          <Footer/>
      </div>
  );
}

export default Industriel;
