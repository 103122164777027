import React from 'react';
import {useParams} from "react-router-dom";
import {useContentId} from "../services/api/contents/use-contents";
import Footer from "../components/Footer";

function Blog() {
  const { id } = useParams();
  const {data: content} = useContentId(id);
  console.log(JSON.stringify(content));

  return (
      <div className="App">
          <div className="section-padding" />
          <div className="container">
              <div className="row">
                  <div className="col-md-12 text-center caption mt-90">
                      <h1>{content?.title}</h1>
                      <h5>{content?.subtitle}</h5>
                  </div>
              </div>
          </div>
          <div className="section-padding">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div dangerouslySetInnerHTML={{__html: content?.description}}/>
                      </div>
                  </div>

              </div>
          </div>
          <Footer/>
      </div>
  );
}

export default Blog;
