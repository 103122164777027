import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useContents} from "../services/api/contents/use-contents";
import Footer from "../components/Footer";
import {useMember} from "../services/api/members/use-member";

function Installateur() {
    const navigate = useNavigate();
    const {data: contents} = useContents();
    const products = contents?.filter(content => content.content_type === "products" && content.content_id === null);
    const [name, setName] = useState('');
    const [firstname, setFirstname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codepostal, setCodepostal] = useState('');
    const [ville, setVille] = useState('');
    const [secteur, setSecteur] = useState('');
    const [region, setRegion] = useState('');
    const [siret, setSiret] = useState('');
    const [naf, setNaf] = useState('');
    const { mutateAsync: createMember } = useMember();

    const handleSubmitInstallateur = async () => {
        try {
            if (!!name && !!firstname && !!email && !!company && !!adresse && !!codepostal && !!ville && !!siret && !!naf && !!secteur) {
                const response = await createMember({ name, firstname, phone, email, company, secteur, adresse, codepostal, ville, region, siret, naf });
                if (response) {
                    setName('');
                    setFirstname('');
                    setPhone('');
                    setEmail('');
                    setCompany('');
                    setAdresse('');
                    setCodepostal('');
                    setVille('');
                    setSecteur('');
                    setRegion('');
                    setSiret('');
                    setNaf('');
                    navigate('/');
                    navigate(0);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onsubmit = (e) => {
        e.preventDefault();
    }

  return (
      <div className="App">
          <div className="row">
              <div className="offset-1 col-md-10">
                  <div className="section-padding"/>
                  <h6>Devenez membre du réseau P&M</h6>
                  <form onSubmit={onsubmit} className="row">
                      <div className="col-md-12">
                          <input
                              placeholder="Nom *"
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                          />
                      </div>
                      <div className="col-md-12">
                          <input
                              placeholder="Prénom *"
                              type="text"
                              id="firstname"
                              name="firstname"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                          />
                      </div>
                       <div className="col-md-12">
                        <input
                            placeholder="Email *"
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-12">
                        <input
                            placeholder="Téléphone"
                            type="text"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                      <div className="col-md-12">
                          <input
                              placeholder="Entreprise *"
                              type="text"
                              id="company"
                              name="company"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              required
                          />
                      </div>
                      <div className="col-md-12">
                          <input
                              placeholder="Adresse *"
                              type="text"
                              id="adresse"
                              name="adresse"
                              value={adresse}
                              onChange={(e) => setAdresse(e.target.value)}
                              required
                          />
                      </div>
                      <div className="col-md-12">
                          <input
                              placeholder="Code postal *"
                              type="text"
                              id="codepostal"
                              name="codepostal"
                              value={codepostal}
                              onChange={(e) => setCodepostal(e.target.value)}
                              required
                          />
                      </div>

                      <div className="col-md-12">
                          <input
                              placeholder="Ville *"
                              type="text"
                              id="ville"
                              name="ville"
                              value={ville}
                              onChange={(e) => setVille(e.target.value)}
                              required
                          />
                      </div>

                      <div className="col-md-12">
                          <input
                              placeholder="Région *"
                              type="text"
                              id="region"
                              name="region"
                              value={region}
                              onChange={(e) => setRegion(e.target.value)}
                          />
                      </div>

                      <div className="col-md-12">
                          <input
                              placeholder="SIRET *"
                              type="text"
                              id="siret"
                              name="siret"
                              value={siret}
                              onChange={(e) => setSiret(e.target.value)}
                              required
                          />
                      </div>

                      <div className="col-md-12">
                          <input
                              placeholder="NAF *"
                              type="text"
                              id="naf"
                              name="naf"
                              value={naf}
                              onChange={(e) => setNaf(e.target.value)}
                              required
                          />
                      </div>

                      <div className="col-md-12">
                          <select id="secteur" value={secteur} onChange={(e) => setSecteur(e.target.value)}>
                              <option value="">Secteur *</option>
                              {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                                  <option value={item.id}>{item.title}</option>)))}
                          </select>
                      </div>
                      <div className="col-md-12">
                          <button type="submit" onClick={() => handleSubmitInstallateur()}>Envoyer</button>
                      </div>

                  </form>
              </div>
          </div>
          <Footer/>
      </div>
  );
}

export default Installateur;
