import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

export const Search = ({s, setS, setSearchVisible}) => {
    const navigate = useNavigate();
    useEffect(() => {
        const listener = event => {
            console.log("s" + s);
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                setSearchVisible(false);
                navigate('/search/' + s);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [s]);

    return (
        <ContainerInput>
            <Icon icon={faSearch} />
            <InputStyle
                type="text"
                placeholder="Rechercher..."
                className="search-input"
                value={s}
                onChange={(e) => setS(e.target.value)}
            />
        </ContainerInput>
    );
}

const ContainerInput = styled.div`
    width: 600px;
    height: 50px;
    padding-left: 20px;
    display: flex;
    align-items: center;
`
const Icon = styled(FontAwesomeIcon)`
    font-size: 16px;
    margin-bottom: 10px;
    margin-right: 10px
`
const InputStyle = styled.input`

`
