import './App.css';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Content from "./pages/Content";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import ContentShow from "./pages/ContentShow";
import Contact from "./pages/Contact";
import Coming from "./pages/Coming";
import ScrollToTop from "./services/utils/ScrollToTop";
import Nav from "./components/Nav";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ResultsSearch} from "./pages/ResultsSearch";
import Video from "./pages/Video";
import Architecte from "./pages/Architecte";
import Installateur from "./pages/Installateur";
import Industriel from "./pages/Industriel";
import QuickActions from "./components/QuickActions";


function App() {
    const [searchVisible, setSearchVisible] = useState(false);

    const closeSearch = () => {
        setSearchVisible(false);
    };

    return (
        <Router>
            <ScrollToTop />
            <Nav searchVisible={searchVisible} setSearchVisible={setSearchVisible} onClose={closeSearch} />
            <BlurBackground isBlurred={searchVisible}>
                <Routes>
                    {/*<Route path="/" element={<Coming />} />*/}
                    <Route path="/" element={<Home />} />
                    <Route path="/contents/:id" element={<Content />} />
                    <Route path="/contents/:id/show" element={<ContentShow />} />
                    <Route path="/news/:id" element={<Blog />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/search/" element={<ResultsSearch />} />
                    <Route path="/search/:search" element={<ResultsSearch />} />
                    <Route path="/video" element={<Video />} />
                    <Route path="/installateur" element={<Installateur />} />
                    <Route path="/architecte" element={<Architecte />} />
                    <Route path="/industriel" element={<Industriel />} />
                    <Route path="*" element={<Home />} />  {/* Route pour pages non trouvées */}
                </Routes>
                <QuickActions />
            </BlurBackground>
        </Router>

    );
}

export default App;


const BlurBackground = styled.div`
    filter: ${(props) => (props.isBlurred ? 'blur(5px)' : 'none')};
    transition: filter 0.3s ease-in-out;
`;
